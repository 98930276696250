body {
  margin: 0;
  padding: 0;
  background-color: #f8f9fa
}
div.top {
  border-bottom:1px solid;
  text-align:center;
  /* font-size: 0.8em; */
}
div.bottom {
  /* font-size: 0.8em; */
  border-top: 1px solid;
  text-align: center;
}
